"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInschrijvingLeningen = exports.getHypothecaireInschrijvingBundles = exports.getHypothecaireInschrijvingDefaults = void 0;
const uuid_1 = require("uuid");
const date_1 = require("../../utils/date");
const map_utils_1 = require("../map-utils");
function getHypothecaireInschrijvingDefaults(defaults) {
    if (defaults.find((v) => v.$type === 'Woning')) {
        const woningDefaults = defaults.find((v) => v.$type === 'Woning');
        return woningDefaults.hypothecaireInschrijvingen[0];
    }
    else {
        return {
            id: '0',
            rang: 1,
            bedrag: {
                waarde: 0,
            },
        };
    }
}
exports.getHypothecaireInschrijvingDefaults = getHypothecaireInschrijvingDefaults;
/**
 * Get a list of (potential) hypothecaireInschrijvingen and their related mortgageParts.
 *
 * Groups MortageParts based on hdnMijCode and startDate.
 * Matching MortgageParts will result in a single HypothecaireInschrijving.
 * Both hdnMijCode and the startDate have to match!
 *
 * @param mortgageParts All mortgage parts from the Summary
 * @param defaults The OmniPlan defaults for the HypothecaireInschrijving
 * @returns List of potential hypothecaire inschrijvingen with the related MortgageParts
 */
function getHypothecaireInschrijvingBundles(mortgageParts, defaults) {
    const mortgageGroups = {};
    mortgageParts.forEach((mortgagePart) => {
        var _a, _b;
        const id = ((_a = mortgagePart.hdnMijCode) !== null && _a !== void 0 ? _a : '') + mortgagePart.begindatumBerekend + ((_b = mortgagePart.relatedTo) === null || _b === void 0 ? void 0 : _b.sort().join('-'));
        if (!mortgageGroups[id]) {
            mortgageGroups[id] = new Set();
        }
        mortgageGroups[id].add(mortgagePart);
    });
    // Sort groups on startDate to get a slightly bigger chance at correctly predicting the "rang"
    const inschrijvingenSets = Object.values(mortgageGroups)
        .map((mortgageSet) => Array.from(mortgageSet))
        .sort((groupA, groupB) => {
        const startDateA = date_1.DateUtils.fromAqopiDate(groupA[0].begindatumBerekend);
        const startDateB = date_1.DateUtils.fromAqopiDate(groupB[0].begindatumBerekend);
        if (!startDateA || !startDateB) {
            return 0; // No startdate = no idea
        }
        return +startDateA - +startDateB;
    });
    return inschrijvingenSets.map((mortgageGroupParts, index) => {
        const totalAmount = mortgageGroupParts.reduce((totalAmount, { startBedrag }) => totalAmount + (startBedrag !== null && startBedrag !== void 0 ? startBedrag : defaults.bedrag.waarde), 0);
        return {
            inschrijving: Object.assign(Object.assign({}, defaults), { id: (0, uuid_1.v4)(), rang: index + 1, bedrag: Object.assign(Object.assign({}, defaults.bedrag), { waarde: totalAmount }), maatschappij: getMaatschappij(mortgageGroupParts[0]) }),
            mortgageParts: mortgageGroupParts,
        };
    });
}
exports.getHypothecaireInschrijvingBundles = getHypothecaireInschrijvingBundles;
function getInschrijvingLeningen(inschrijvingen, inschrijvingenMortgageParts, clientId) {
    const leningInputDetails = [];
    const keys = ['Client', 'Partner', 'Samen'];
    inschrijvingenMortgageParts.forEach((mortgageParts, index) => {
        const inschrijving = inschrijvingen[index];
        const parts = {
            Client: [],
            Partner: [],
            Samen: [],
        };
        mortgageParts.forEach((part) => {
            const toewijzing = (0, map_utils_1.getToewijzing)(part, clientId);
            parts[toewijzing].push(part);
        });
        keys.forEach((toewijzing) => {
            if (parts[toewijzing].length) {
                leningInputDetails.push({
                    inschrijving,
                    mortgageParts: parts[toewijzing],
                    toewijzing,
                });
            }
        });
    });
    return leningInputDetails;
}
exports.getInschrijvingLeningen = getInschrijvingLeningen;
function getMaatschappij(mortgagePart) {
    if (mortgagePart.hdnMijCode && mortgagePart.maatschappijAfgeleid) {
        return {
            code: mortgagePart.hdnMijCode,
            naam: mortgagePart.hdnMijCode,
            weergaveNaam: mortgagePart.maatschappijAfgeleid,
        };
    }
}
