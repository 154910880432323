"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapHypothecaireLeningen = void 0;
const date_1 = require("../../utils/date");
var fromAqopiDate = date_1.DateUtils.fromAqopiDate;
const map_hypothecaire_leningdelen_1 = require("./map-hypothecaire-leningdelen");
const clone_1 = require("../../utils/clone");
const toewijzingDefaultsMap = {
    Client: 'client',
    Partner: 'partner',
    Samen: 'couple',
};
function mapHypothecaireLeningen(inschrijvingLeningen, taxTermEndDateCollection, clientId, leningDefaults, leningdeelDefaults) {
    const leningen = [];
    inschrijvingLeningen.forEach(({ inschrijving, mortgageParts, toewijzing }) => {
        const lening = mapHypothecaireLening(inschrijving, mortgageParts, leningDefaults[toewijzingDefaultsMap[toewijzing]][0]);
        lening.onderwerpen = (0, map_hypothecaire_leningdelen_1.mapHypothecaireLeningdelen)(mortgageParts, taxTermEndDateCollection, clientId, leningdeelDefaults);
        leningen.push(lening);
    });
    return leningen;
}
exports.mapHypothecaireLeningen = mapHypothecaireLeningen;
function mapHypothecaireLening(inschrijving, mortgageParts, defaults) {
    defaults = (0, clone_1.clone)(defaults);
    const hypothecaireLening = Object.assign(Object.assign({}, defaults), { hypothecaireInschrijving: inschrijving });
    const sortedByStart = [...mortgageParts].sort((a, b) => {
        var _a, _b, _c, _d;
        return ((_b = (_a = fromAqopiDate(a.begindatumBerekend)) === null || _a === void 0 ? void 0 : _a.getTime()) !== null && _b !== void 0 ? _b : 0) > ((_d = (_c = fromAqopiDate(b.begindatumBerekend)) === null || _c === void 0 ? void 0 : _c.getTime()) !== null && _d !== void 0 ? _d : 0)
            ? 1
            : -1;
    });
    const sortedByEnd = [...mortgageParts].sort((a, b) => {
        var _a, _b, _c, _d;
        return ((_b = (_a = fromAqopiDate(a.einddatumBerekend)) === null || _a === void 0 ? void 0 : _a.getTime()) !== null && _b !== void 0 ? _b : 0) > ((_d = (_c = fromAqopiDate(b.einddatumBerekend)) === null || _c === void 0 ? void 0 : _c.getTime()) !== null && _d !== void 0 ? _d : 0)
            ? 1
            : -1;
    });
    const start = date_1.DateUtils.toDateTimeString(fromAqopiDate(sortedByStart[0].begindatumBerekend));
    const end = date_1.DateUtils.toDateTimeString(fromAqopiDate(sortedByEnd[0].einddatumBerekend));
    if (start && end) {
        hypothecaireLening.duur = Object.assign(Object.assign({}, defaults.duur), { ingangsdatum: start, einddatum: end });
    }
    return hypothecaireLening;
}
